import { ReactNode } from 'react';
import { Box } from '@mui/material';

import theme from '../../styles/theme';

interface MainProps {
  children: ReactNode;
  drawerWidth: number;
  customStyle?: object;
}

function Main(props: MainProps) {
  const { children, drawerWidth, customStyle = {} } = props;
  return (
    <Box
      // only this box on all project must be main
      component={'main'}
      sx={{
        p: theme.spacing(10),
        my: theme.spacing(15),
        bgcolor: theme.palette.background.default,
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        minHeight: `calc(100vh - ${60}px - ${60}px)`,
        ...customStyle,
      }}
    >
      {children}
    </Box>
  );
}

export default Main;
