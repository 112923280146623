import { KeyboardEvent } from 'react';
import { Search } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Controller, Control, FieldValues } from 'react-hook-form';

interface ControlledSearchInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: string;
  controllerProps?: any;
  inputsProps?: any;
  onSearch: () => any;
  errors: any;
  isDirty: boolean;
}

const ControlledSearchInput = <T extends FieldValues>(
  props: ControlledSearchInputProps<T>
) => {
  const endAdornment = (
    <InputAdornment position="end" sx={{ marginRight: 1 }}>
      <IconButton
        color="primary"
        aria-label="upload picture"
        component="label"
        onClick={props.onSearch}
      >
        <Search />
      </IconButton>
    </InputAdornment>
  );

  const handleKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      props.onSearch();
    }
  };

  return (
    <Controller
      {...props.controllerProps}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            {...props.inputsProps}
            label={props.label}
            type="text"
            name={props.name}
            value={value}
            onChange={onChange}
            onKeyUp={handleKeyUp}
            InputProps={{ endAdornment }}
            helperText={props.isDirty && props.errors && props.errors.message}
            error={props.isDirty && Boolean(props.errors)}
          />
        );
      }}
    />
  );
};

export default ControlledSearchInput;
