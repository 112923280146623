import * as Sentry from "@sentry/nextjs";
import env from './utils/env';
import { getVersion } from './utils/version';

const APP_NAME = env('APP_NAME');
const SENTRY_DSN = env('SENTRY_DSN');
const ENVIRONMENT = env('ENVIRONMENT');

Sentry.init({
  dsn: SENTRY_DSN,
  environment: `${APP_NAME}-${ENVIRONMENT}`,
  release: `${APP_NAME}-api-ui:${getVersion()}`,
});
