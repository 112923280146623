import { createContext } from 'react';

export const AppNameContext = createContext<string | null>(null);

interface AppNameProps {
  appName: string;
  children: React.ReactNode;
}

export function AppNameProvider(props: AppNameProps): JSX.Element | null {
  const { appName, children } = props;

  return (
    <AppNameContext.Provider value={appName}>
      {children}
    </AppNameContext.Provider>
  );
}
