import { CSSProperties } from 'react';
import './company-loader.css';

interface CompanyLoaderProps {
  wrapperStyles?: CSSProperties;
  svgStyles?: CSSProperties;
}

export const CompanyLoader = (props: CompanyLoaderProps) => {
  const { wrapperStyles, svgStyles } = props;

  return (
    <div className="common_company_loader" style={{ ...wrapperStyles }}>
      <svg
        style={{ ...svgStyles }}
        className="common_company_loader__svg"
        xmlSpace="preserve"
        version="1.1"
        viewBox="0 0 33.866676 44.449997"
        height="5.0mm"
        width="5.0mm"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="m 16.193057,39.94532 c -3.271616,-0.86376 -5.489786,-3.11941 -6.9523157,-6.37348 -0.87813,-2.4244 -0.29197,-5.36109 1.1420197,-8.5944 l 2.85613,-5.77204 c 2.193846,0.72172 4.341786,1.70989 6.005936,4.14178 1.40741,1.81342 2.22053,4.46513 2.06827,8.32398 -0.47072,4.83336 -2.25641,7.48154 -5.04123,8.46942"
          className="common_company_loader__flickering common_company_loader__path--white-flame"
        />
        <path
          d="m 9.6457413,40.26803 c -1.59955,-0.67015 -2.62646,-1.50262 -3.5227,-2.8557 -2.62298,-3.95997 -2.94343,-11.18642 -0.91986,-20.74358 0.39256,-1.85402 0.8559,-3.1653 0.87419,-2.47398 0.004,0.16727 0.42572,0.9248 0.93621,1.68338 l 0.92816,1.37924 -0.66337,2.09993 c -1.24009,3.92557 -1.56245,5.78345 -1.57641,9.08539 -0.0107,2.54193 0.0921,3.4625 0.51575,4.61689 1.08298,2.95097 4.7545797,5.85282 8.5050057,6.72197 l 1.54915,0.35901 -1.05833,0.30115 c -1.536256,0.43715 -4.316836,0.3504 -5.5677957,-0.1737 z m 8.0541057,-0.16679 c -0.0859,-0.0859 0.69137,-1.0178 1.72726,-2.0709 6.30824,-6.41298 7.26445,-18.74409 1.89836,-24.48088 l -1.19853,-1.28132 1.51205,-1.45326 c 2.37049,-2.2783405 2.38464,-2.2841805 2.17432,-0.8983805 -0.42176,2.7791305 0.59396,5.7061605 2.93018,8.4439205 1.77186,2.07639 2.52317,3.42335 3.04446,5.45814 1.40619,5.4889 -2.14668,12.51163 -7.69524,15.21071 -1.7523,0.85239 -4.0514,1.41343 -4.39286,1.07197 z"
          className="common_company_loader__flickering common_company_loader__path--green-flame"
        />
        <path
          d="m 12.906751,39.05268 c -3.2503597,-1.33677 -5.1907697,-3.06124 -6.3639197,-5.6557 -0.77086,-1.7048 -0.93859,-5.96456 -0.35696,-9.06594 0.576,-3.0714 1.76709,-6.86854 2.15453,-6.86854 0.17572,0 0.63728,0.24998 1.0257,0.55551 0.6158,0.48439 2.9423497,1.1161 3.8727897,1.18739 0.35995,0.0276 0.10792,-0.0352 -1.24192,2.45705 -1.89763,3.50359 -3.4869497,7.482 -3.1378797,10.27582 0.36311,2.90628 2.4175997,5.73449 5.1941357,7.15025 1.52627,0.77824 0.77594,0.75479 -1.146476,-0.0358 z m 6.787486,-1.76058 c 0.7428,-1.23358 1.45052,-3.69093 1.61886,-5.62094 0.28891,-3.31248 -0.83734,-7.04691 -2.79909,-9.28121 -0.933,-1.06264 -3.69152,-2.70743 -4.737846,-2.96585 -0.85102,-0.21019 -0.23598,-0.30036 0.397986,-0.86757 0.29151,-0.26081 0.86741,-0.96488 1.30397,-1.52675 0.43657,-0.56187 1.60225,-1.85523 2.59041,-2.87412 l 1.79666,-1.85253 1.15371,1.2859 c 3.67565,4.0968 4.59992,10.4637 2.48276,17.10264 -0.72902,2.28607 -2.17083,5.0254 -3.29912,6.26812 -0.56851,0.62616 -0.76103,0.75202 -0.5083,0.33231 z"
          className="common_company_loader__flickering common_company_loader__path--aquamarine-flame"
        />
        <path
          d="m 15.777147,38.36458 c -0.59211,-1.24742 -0.81424,-1.91825 -0.79902,-3.95949 0.15195,-1.35405 0.29027,-2.73446 0.4693,-3.31655 0.30988,-1.00749 0.34839,-0.83999 0.80175,3.48689 0.26193,2.49986 0.41829,4.70252 0.34746,4.89479 -0.0708,0.19226 -0.43959,-0.30527 -0.81949,-1.10564 z M 11.239261,18.83796 c -1.17245,-0.40541 -3.0371797,-1.49736 -3.0371797,-1.77852 0,-0.20386 2.4559097,-5.84699 3.1062897,-7.1375605 0.36668,-0.72761 1.51441,-2.8112 2.55051,-4.63021 l 1.883826,-3.30729 0.18463,1.67397 c 0.29599,2.68372 1.25462,5.06278 3.01173,7.4742905 l 0.8822,1.21076 -1.45517,1.43507 c -0.80034,0.78929 -2.19427,2.35669 -3.09762,3.48312 -1.802676,2.24787 -1.936186,2.3001 -4.029216,1.57637 z"
          className="common_company_loader__flickering common_company_loader__path--blue-flame"
        />
      </svg>
    </div>
  );
};
