import { Forbidden, ForbiddenProps } from './forbidden';

type ErrorFallbackProps = ForbiddenProps;

export const ErrorFallback = (props: ErrorFallbackProps) => {
  const {
    text = 'Oops! Ha ocurrido un error en el sistema, intenta nuevamente.',
    emoji = ':(',
  } = props;

  return <Forbidden text={text} emoji={emoji} />;
};
