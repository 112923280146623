import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  spacing: 4,
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
  shape: {
    borderRadius: 2,
  },
  palette: {
    primary: {
      main: '#3AC19B',
      light: '#E9F8F3',
    },
    secondary: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#11202D',
      secondary: '#3AC19B',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    background: {
      default: '#F8FBFF',
    },
  },
});

export default theme;
