import { Typography } from '@mui/material';
import Image from 'next/image';

import theme from '../../styles/theme';

import HeartIcon from '../../public/heart-icon.png';

function Footer() {
  return (
    <footer
      style={{
        backgroundColor: 'white',
        width: '100%',
        boxShadow: theme.shadows[5],
        position: 'fixed',
        bottom: 0,
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        height: 60,
        padding: '20px 24px',
      }}
    >
      <Typography
        sx={{ color: theme.palette.text.primary, fontSize: 14, mr: 1 }}
      >
        Hecho con
      </Typography>
      <Image src={HeartIcon} width={20} height={20} alt={'heart-icon'} />
      <Typography
        sx={{ color: theme.palette.text.primary, fontSize: 14, ml: 1 }}
      >
        por Dirección Digital
      </Typography>
    </footer>
  );
}

export default Footer;
