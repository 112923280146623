export class ApiError extends Error {
  public readonly errorCode: string;
  public readonly appName: string;
  public readonly ctx: unknown;
  public readonly cause?: Error;
  public readonly requestId?: string;
  public readonly errorId: string;

  constructor(
    message: string,
    errorId: string,
    appName: string,
    errorCode: string,
    ctx?: unknown,
    requestId?: string,
    cause?: Error
  ) {
    super(message);
    this.name = 'ApiError';
    this.errorId = errorId;
    this.appName = appName;
    this.errorCode = errorCode;
    this.ctx = ctx;
    this.requestId = requestId;
    this.cause = cause;
  }
}
