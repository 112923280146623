import {
  TextField,
  Autocomplete,
  AutocompleteRenderInputParams,
} from '@mui/material';
import { SyntheticEvent, useCallback } from 'react';
import {
  Controller,
  Control,
  FieldValues,
  ControllerProps,
} from 'react-hook-form';
import { useDebouncedCallback } from 'use-debounce';

interface ControlledAsyncSelectInputProps<T extends FieldValues, O> {
  control: Control<T>;
  label: string;
  name: string;
  options: O[];
  isLoading: boolean;
  idField: keyof O;
  descriptionField: keyof O;
  errors?: any;
  isDirty: boolean;
  controllerProps?: any;
  inputsProps?: any;
  onQueryChange: (query: string) => void;
  queryDebounce?: number;
  noOptionsText?: string;
}

const ControlledAsyncSelectInput = <T extends FieldValues, O>({
  label,
  control,
  name,
  options,
  isLoading,
  idField,
  descriptionField,
  isDirty,
  errors,
  inputsProps = {},
  controllerProps = {},
  onQueryChange,
  queryDebounce = 500,
  noOptionsText = 'No se encontraron resultados',
}: ControlledAsyncSelectInputProps<T, O>) => {
  const getOptionLabel = useCallback(
    (option: O) => `${option[idField]} - ${option[descriptionField]}`,
    [descriptionField, idField]
  );

  const renderOption = useCallback(
    (props: any, option: O) => (
      <li {...props} key={option[idField]}>
        {option[idField]} - {option[descriptionField]}
      </li>
    ),
    [descriptionField, idField]
  );

  const isOptionEqual = useCallback(
    (a: O, b: O) => a[idField] === b[idField],
    [idField]
  );

  const inputRenderFn = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          label={label}
          helperText={isDirty && errors && errors.message}
          error={isDirty && Boolean(errors)}
        />
      );
    },
    [errors, isDirty, label]
  );

  const onQueryChangeProxy = useCallback(
    (_: SyntheticEvent<Element, Event>, value: string) => {
      onQueryChange(value);
    },
    [onQueryChange]
  );
  const debouncedQueryChange = useDebouncedCallback(
    onQueryChangeProxy,
    queryDebounce
  );

  const autoCompleteRenderFn = useCallback<ControllerProps['render']>(
    ({ field: { value, onChange } }) => {
      return (
        <Autocomplete
          value={value}
          {...inputsProps}
          loading={isLoading}
          loadingText="Cargando..."
          disablePortal
          options={options}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={isOptionEqual}
          renderOption={renderOption}
          renderInput={inputRenderFn}
          onChange={(_, data: O) => onChange(data)}
          onInputChange={debouncedQueryChange}
          noOptionsText={noOptionsText}
        />
      );
    },
    [
      debouncedQueryChange,
      getOptionLabel,
      inputRenderFn,
      inputsProps,
      isLoading,
      isOptionEqual,
      options,
      renderOption,
      noOptionsText,
    ]
  );

  return (
    <Controller
      {...controllerProps}
      control={control}
      name={name}
      render={autoCompleteRenderFn}
    />
  );
};

export default ControlledAsyncSelectInput;
