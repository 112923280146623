import React from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  ArrowDropDownOutlined,
  Settings,
  PersonOutlineOutlined,
  SvgIconComponent,
} from '@mui/icons-material';
import theme from '../../../styles/theme';

export const ProfileButton = () => {
  return (
    <IconButton
      sx={{
        borderRadius: 2,
        width: 35,
        height: 35,
        color: theme.palette.common.white,
      }}
    >
      <PersonOutlineOutlined
        sx={{
          width: 28,
          height: 28,
        }}
      />
    </IconButton>
  );
};

export const SettingButton = () => {
  return (
    <IconButton
      sx={{
        backgroundColor: '#D3F1E8',
        borderRadius: 2,
        ':hover': { backgroundColor: '#c4ddd5' },
      }}
    >
      <Settings sx={{ color: '#3AC19B' }} />
    </IconButton>
  );
};

export const DropDownButton = () => {
  return (
    <IconButton
      sx={{
        flex: 1,
        ':hover': { backgroundColor: 'transparent' },
      }}
    >
      <ArrowDropDownOutlined />
    </IconButton>
  );
};

export const MenuWrapper = (
  props: React.PropsWithChildren<{
    menuItems: {
      icon?: SvgIconComponent;
      label: string;
      onClick?: () => void;
    }[];
  }>
) => {
  const { menuItems, children: MenuAnchorElement } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (handler?: () => void) => {
    if (handler) {
      handler();
    }
  };

  return (
    <>
      {React.cloneElement(MenuAnchorElement as JSX.Element, {
        onClick: handleClick,
      })}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableScrollLock
      >
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            disabled={!menuItem.onClick}
            onClick={() => {
              handleClose();
              handleItemClick(menuItem.onClick);
            }}
          >
            {menuItem.icon && (
              <ListItemIcon>
                <menuItem.icon />
              </ListItemIcon>
            )}
            <ListItemText primary={menuItem.label} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
