import React, { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Controller, Control, FieldValues } from 'react-hook-form';
import { DateTime } from 'luxon';

interface ControlledDesktopDatePickerInputProps<T extends FieldValues> {
  control: Control<T>;
  label: string;
  name: string;
  inputWidth?: string;
  controllerProps?: any;
  inputsProps?: any;
  errors: any;
  isDirty: boolean;
  displayFormat?: string;
  onEnterKeyPress?: () => void;
  autofocus?: boolean;
  resetKey?: string | number;
  withTime?: boolean;
}

const ControlledDesktopDatePickerInput = <T extends FieldValues>(
  props: ControlledDesktopDatePickerInputProps<T>
) => {
  const { displayFormat = 'dd/MM/yyyy HH:mm:ss' } = props;

  const DefaultDatePickerComponent = props.withTime
    ? DesktopDateTimePicker
    : DesktopDatePicker;

  const handleOnEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter' && props.onEnterKeyPress) {
      props.onEnterKeyPress();
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current != null && props.autofocus) {
      inputRef.current.focus();
    }
  }, [props.autofocus, props.resetKey]);

  return (
    <Controller
      {...props.controllerProps}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value } }) => {
        return (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DefaultDatePickerComponent
              inputRef={inputRef}
              {...props.inputsProps}
              inputFormat={displayFormat}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size={props.inputsProps?.size}
                  sx={{
                    width: props.inputWidth || '100%',
                    ...(props.inputsProps?.sx || {}),
                  }}
                  helperText={
                    props.isDirty && props.errors && props.errors.message
                  }
                  error={props.isDirty && Boolean(props.errors)}
                  onKeyDown={
                    props.onEnterKeyPress ? handleOnEnterKeyPress : undefined
                  }
                />
              )}
              label={props.label}
              name={props.name}
              value={!value ? null : value}
              onChange={(e: DateTime | null) => onChange(e?.toJSDate())}
            />
          </LocalizationProvider>
        );
      }}
    />
  );
};

export default ControlledDesktopDatePickerInput;
