import { Drawer, List, ListItem } from '@mui/material';

import ListItemMenuButton from './buttons/list-item-menu-button';

interface AsideMenuProps {
  defaultDrawerWidth: number;
  drawerWidth?: number;
  buttonStyle?: object;
  asideMenuOptions?: {
    title: string;
    path?: string;
    icon: React.ReactNode;
  }[];
}

function AsideMenu({
  defaultDrawerWidth,
  drawerWidth,
  asideMenuOptions,
  buttonStyle,
}: AsideMenuProps) {
  return (
    <Drawer
      sx={{
        width: drawerWidth || defaultDrawerWidth,
        boxShadow: 5,
        zIndex: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth || defaultDrawerWidth,
          boxSizing: 'border-box',
          py: 8,
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {asideMenuOptions?.map((option) => (
          <ListItem disablePadding key={option.path}>
            <ListItemMenuButton
              buttonStyle={buttonStyle}
              text={option.title}
              icon={option.icon}
              path={option.path}
            />
          </ListItem>
        )) ?? null}
      </List>
    </Drawer>
  );
}

export default AsideMenu;
