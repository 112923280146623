/* eslint-disable react/forbid-prop-types */
import React, { useContext } from 'react';
import { AppNameContext } from '../contexts/AppNameProvider';

interface UserInterface {
  roles: string[];
}

interface PolicyProps<TUser extends UserInterface> {
  user?: TUser | null;

  roles: string[];
  children: React.ReactNode;

  fallback?: React.ReactNode;
}

export function Policy<TUser extends UserInterface>(
  props: PolicyProps<TUser>
): JSX.Element | null {
  const { user, roles, children, fallback } = props;
  const appName = useContext(AppNameContext);

  if (!appName) {
    throw new Error('AppNameContext is not set.');
  }

  const authorized =
    user && roles.every((role) => user.roles.includes(`${appName}/${role}`));

  if (authorized) {
    return <>{children}</>;
  }

  if (fallback) {
    return <>{fallback}</>;
  }

  return null;
}
